var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.itemsMenu),function(item,index){return _c('li',{key:index,staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:[
              _vm.isActive && 'menu-item-active',
              _vm.isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[(!item.subItems)?_c('router-link',{attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"text-dark menu-icon",class:{ [item.iconClass]: true }}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))])])]}}],null,true)}):_vm._e(),(_vm.lista_permissoes_filial.ra_Empresa == item.permissao ||
           _vm.lista_permissoes_filial.ra_funcionario == item.permissao ||
           _vm.lista_permissoes_filial.ra_Usuario == item.permissao ||
           _vm.lista_permissoes_filial.ra_FinanPedidosReceber == item.permissao ||
           _vm.lista_permissoes_filial.ra_Imovel == item.permissao ||
           _vm.lista_permissoes_filial.ra_Contrato == item.permissao ||


           _vm.lista_permissoes_filial.ra_FinanCaixa == item.permissao

           )?_c('a',{staticClass:"menu-link menu-toggle",class:[
              _vm.isActive && 'menu-item-active',
              _vm.isExactActive && 'menu-item-active',
            ],attrs:{"href":"#"}},[_c('i',{staticClass:"text-dark menu-icon",class:{ [item.iconClass]: true }}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('i',{staticClass:"text-white menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((item.subItems),function(subItem,index){return _c('router-link',{key:index,attrs:{"to":subItem.route},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(subItem.title)+" ")])])])]}}],null,true)})}),1)])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }