export default [
  {
    title: "Página Inicial",
    route: "/dashboard",
    permissao: "paginainicial",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    permissao: "ra_Empresa",
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "ra_Role",
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "ra_Empresa",
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "ra_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "ra_Perfil",
      },

    ],
  },

  {
    title: "RH",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-user",
    subItems: [
      {
        title: "Usuários",
        route: "/gerenFuncionarios",
        permissao: "c_Usuario",
      },
    ],
  },
  {
    title: "Eventos",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Eventos",
        route: "/evento",
        permissao: "ra_Usuario",
      },
      // {
      //   title: "Taxa Eventos",
      //   route: "/taxaEventos",
      //   permissao: "ra_pdv",
      // },

      {
        title: "Pontos de venda",
        route: "/pdv",
        permissao: "ra_Evento",
      },

      {
        title: "Seleciona PDV",
        route: "/pdvsEvento",
        permissao: "ra_Evento",
      },
     
      // {
      //   title: "Ingressos",
      //   route: "/ingressos",
      //   permissao: "ra_Evento",
      // },
      {
        title: "Setor",
        route: "/setor",
        permissao: "ra_Setor",

      }

    ]
  },

  {
    title: "Sistema",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-ticket-alt",
    subItems: [
      {
        title: "Gêneros",
        route: "/categoria",
        permissao: "ra_Usuario",
      },
  
      {
        title: "Parceiros",
        route: "/parceiros",
        permissao: "ra_Evento",

      },
      {
        title: "Servicos",
        route: "/servicos",
        permissao: "ra_Evento",
      },
      {
        title: "Maquininhas",
        route: "/maquininhas",
        permissao: "ra_Evento",
      },
      {
        title: "faturas",
        route: "/faturas",
        permissao: "ra_Evento",
      },
      {
        title: "Validacao",
        route: "/validacao",
        permissao: "ra_Evento",
      },
      {
        title: "beneficiados",
        route: "/beneficiados",
        permissao: "ra_Usuario",
      },
   
    ]
  }
  // {
  //   title: "Incresos Pdv",
  //   route: "/incresosPdv",
  //   permissao: "ra_Evento",
  // },

  




  // {
  //   title: "Imoveis",
  //   route: null,
  //   permissao: "ra_Imovel",
  //   iconClass: "fas fa-home",
  //   subItems: [
  //     {
  //       title: "Imoveis",
  //       route: "/imovel",
  //       permissao: "ra_Imovel",
  //     },
  //   ],
  // },
  // {
  //   title: "Contratos",
  //   route: null,
  //   permissao: "ra_Contrato",
  //   iconClass: "fas fa-home",
  //   subItems: [
  //     {
  //       title: "Contratos",
  //       route: "/contrato",
  //       permissao: "ra_Contrato",
  //     },
  //   ],
  // },







 

 
];
