export default [

    {
        title: "Perfil",
        botoes: [
            {
                title: "Criar",
                route: "/createPerfil",
                permissao: "c_Perfil",
            },
            {
                title: "Listar",
                route: "/perfil",
                permissao: "ra_Perfil",
            },
        ],
    },

    {
        title: "Permissoes",
        botoes: [
            {
                title: "Criar",
                route: "/createRule",
                permissao: "c_Role",
            },
            {
                title: "Listar",
                route: "/permissoes",
                permissao: "ra_Role",
            },
        ]
    },
    {
        title: "Empresa",
        botoes: [
            {
                title: "Criar",
                route: "/createEmpresa",
                permissao: "ra_empresa",
            },
            {
                title: "Listar",
                route: "/empresa",
                permissao: "ra_empresa",
            },
        ]
    },
    {
        title: "Filial",
        botoes: [
            {
                title: "Criar",
                route: "/createFilial",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/filial",
                permissao: "ra_Filial",
            },
        ]
    },
    {
        title: "Evento",
        botoes: [
            {
                title: "Criar",
                route: "/createEvento",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/evento",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Funcionarios",
        botoes: [
            {
                title: "Criar",
                route: "/createFuncionario",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/gerenFuncionarios",
                permissao: "ra_Usuario",
            },
        ],
    },

    {
        title: "Categoria",
        botoes: [
            {
                title: "Criar",
                route: "/createCategoria",
                permissao: "c_Usuario",
            },
            {
                title: "ListarCategoria",
                route: "/categoria",
                permissao: "ra_Usuario",
            },
        ]
    },
    
    
    {
        title: "Pdv",
        botoes: [
            {
                title: "Criar",
                route: "/createPdv",
                permissao: "c_Pdv",
            },
            {
                title: "Listar",
                route: "/pdv",
                permissao: "ra_Pdv",
            },
        ]
    },
    {
        title: "Taxa Eventos",
        botoes: [
            {
                title: "Criar",
                route: "/createTaxaEventos",
                permissao: "c_Pdv",
            },
            {
                title: "Listar",
                route: "/taxaEventos",
                permissao: "ra_Pdv",
            },
        ]
    },
    {
        title: "Maquininha",
        botoes: [
            {
                title: "Criar",
                route: "/createMaquininhas",
                permissao: "c_Pdv",
            },
            {
                title: "Listar",
                route: "/maquininhas",
                permissao: "ra_Pdv",
            },
        ]
    },


        
    {
        title: "Ingressos",
        botoes: [
            {
                title: "Criar",
                route: "/createIngressos",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/ingressos",
                permissao: "ra_Usuario",
            },
        ]
    },
    {
        title: "Pdvs Evento",
        botoes: [
            {
                title: "Criar",
                route: "/createPdvsEvento",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/pdvsEvento",
                permissao: "ra_Usuario",
            },
        ]
    },

    {
        title: "Notas e Servicos",
        botoes: [
            {
                title: "Criar",
                route: "/createServicos",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/servicos",
                permissao: "ra_Usuario",
            },
        ]
    },

    {
        title: "Parceiros",
        botoes: [
            {
                title: "Criar",
                route: "/createParceiros",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/parceiros",
                permissao: "ra_Usuario",
            },
        ]
    },
    {
        title: "Setor",
        botoes: [
            {
                title: "Criar",
                route: "/createSetor",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/setor",
                permissao: "ra_Usuario",
            },
        ]
    },
    
    // {
    //     title: "Imoveis",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createImovel",
    //             permissao: "c_Imovel",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/imovel",
    //             permissao: "ra_Imovel",
    //         },
    //     ],
    // },
    // {
    //     title: "Contratos",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createContrato",
    //             permissao: "c_Contrato",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/contrato",
    //             permissao: "ra_Contrato",
    //         },
    //     ],
    // },


  

    


  




];
